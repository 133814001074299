import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  ButtonBase,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useEffect, useState } from "react";
import SelectLanguage from "./selectLanguage";
import { MenuRounded } from "@mui/icons-material";
import TemporaryDrawer from "./drawer";
import menuSvg from "./assets/menu.svg";
import LangModal from "../../views/ContentParticulars/Modal2";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import CustomTooltip from "../../components/Tooltip";
import "./styles.scss";

import clsx from "clsx";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: "white",
    boxShadow: "0px 10px 30px #0000001A",
    height: "90px !important",
    padding: "8px 10px",
    zIndex: "1001",
    [theme.breakpoints.down("sm")]: {
      height: "80px !important",
    },
  },
  toolbar: {
    minHeight: "unset",
    height: "90px !important",
    [theme.breakpoints.down("sm")]: {
      height: "80px !important",
    },
  },
  citiisLogo: {
    height: 70,
    [theme.breakpoints.down("md")]: {
      height: 60,
    },
  },
  logosWrapperBox: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  upperCitiisLogo: {
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
  },
  buttons: {
    background: "#765B26",
    padding: "0 20px",
    margin: "1px 10px",
    boxShadow: "0px 10px 10px #00000029",
    height:
      localStorage.getItem("SengolLanguage") === "ml"
        ? "78px !important"
        : localStorage.getItem("SengolLanguage") === "ta"
        ? "70px"
        : "50px",
    borderRadius: "100px !important",
    lineHeight: 1.1,
    fontFamily: "Roboto",
    // marginRight: '20px',
    color: "white",
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
      fontSize: "16px",
    },
    [theme.breakpoints.down("xsm")]: {
      width: "185px",
      fontSize: "16px",
      height: "auto",
      minHeight: "52px",
      marginLeft: "0px",
      padding: "5px 19px",
      display: "flex",
      justifyContent: "left",
      gap: "8px",
    },
    [theme.breakpoints.up("xl")]: {
      margin: 0,
      padding: "3px 22px !important",
      fontSize: "18px",
    },
    [theme.breakpoints.up("1500")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("1800")]: {
      margin: 0,
      padding: "6px 38px !important",
      fontSize: "18px",
    },
  },
  whiteIcons: {
    // color: "white",
    color: "#585858",
    marginRight: "5px",
    height: "35px",
    // marginTop: "-5px",
  },
  buttonText: {
    textAlign: "left",
    lineHeight: "1.5",
  },
  faq: {
    fontFamily: "Poppins",
    // fontSize: "12px",
    fontSize: "18px",
    fontWeight: "bold",
    cursor: "pointer",
    paddingRight: "10px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px",
    },
    [theme.breakpoints.down("xsm")]: {
      fontSize: "7px",
    },
    "&:hover": {
      color: "#b59047",
    },
  },
  faq1: {
    display: "flex",
    alignItems: "center",
    "&:hover": {
      color: "#b59047",
    },
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  const [viewModal, setViewModal] = useState(false);

  return (
    <>
      <LangModal viewModal={viewModal} setViewModal={setViewModal} />
      <TemporaryDrawer open={open} setOpen={setOpen} />
      <AppBar position="fixed" elevation={10} className={classes.appbar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.logosWrapperBox}>
            <img
              src={require("./assets/govtofindia.png")}
              alt="citiis"
              className={classes.upperCitiisLogo}
            />
            {window.innerWidth < 800 && (
              <IconButton onClick={() => setOpen(true)}>
                <img src={menuSvg} />
              </IconButton>
            )}
            {window.innerWidth < 800 && <SelectLanguage notdisplay={true} />}
            {window.innerWidth > 800 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                {location.pathname != "/" &&
                  location.pathname !=
                    `/contentsmain/${localStorage.getItem(
                      "SengolLanguage"
                    )}` && (
                    // <CustomTooltip title={t("CLICK_TO_GO_TO_HOMEPAGE")}>

                    <div
                      className={clsx(classes.faq1, "homeiconheaderdiv")}
                      onClick={() => {
                        // navigate(`/faq`);
                        navigate(
                          `/contentsmain/${localStorage.getItem(
                            "SengolLanguage"
                          )}`
                        );
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <HomeOutlinedIcon
                        className={clsx(classes.whiteIcons, "homeiconheader")}
                      />
                      <Typography
                        textAlign="center"
                        sx={{ color: "#585858", marginRight: "20px" }}
                        className={clsx(classes.faq, "homeiconheadertext")}
                      >
                        Main Home
                      </Typography>
                    </div>
                    // </CustomTooltip>
                  )}

                <Typography
                  onClick={() => {
                    navigate(`/faq`);
                  }}
                  textAlign="center"
                  sx={{ color: "#585858", marginRight: "20px" }}
                  className={clsx(classes.faq)}
                >
                  {t("FAQ")}
                </Typography>
                {location.pathname !== "/" && (
                  <SelectLanguage setViewModal={setViewModal} />
                )}
                <img
                  src={require("./assets/azadikamahotsav.png")}
                  alt="citiis"
                  className={classes.upperCitiisLogo}
                />
                <img
                  src={require("./assets/g20.png")}
                  alt="citiis"
                  style={{ marginLeft: 10 }}
                  className={classes.upperCitiisLogo}
                />
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
