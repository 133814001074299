import Header from "./components/Header";
import makeStyles from "@mui/styles/makeStyles";
import { Box, ButtonBase, Paper } from "@mui/material";
import clsx from "clsx";
import background from "./assets/backgroundfinal.svg";
import danda from "./assets/danda.png";
import { AppRoutes } from "./routes";
import './i18n'
import DocumentMeta from "react-document-meta";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "90px 0 0 0",
    [theme.breakpoints.down('sm')]: {
      padding: '50px 0 0 0'
    }
  },
  body: {
    height: "calc(100vh - 90px)",
    background: `url(${background})`,
    backgroundSize: "cover",
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 50px)'
    }
  },
  backgroundImage2: {
    position: "absolute",
  },
  danda: {
    position: "absolute",
    height: "calc(110%)",
    left: "10vw",
    bottom: 0,
    objectPosition: '0 10vh',
    [theme.breakpoints.down('xlg')]: {
      left: '4vw',
    },
    [theme.breakpoints.down('lg')] : {
      left: '0',
      objectPosition: '-50px 10vh'
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(60%)',
      objectPosition: '50px -40px',
      position: 'static',
      transform: 'none'
    }
  },
  buttonContainer: {
    position: "absolute",
    top: "50%",
    left: "68%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexWrap: "wrap",
    gap: '20px',
    width: '750px',
    [theme.breakpoints.down('lg')]: {
      width: '600px'
    },
    [theme.breakpoints.down('md')]: {
      width: '450px'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      transform: 'translateY(-60px)',
      width: '100%',
      justifyContent: 'center'
    }
  },
  buttonBaseContainer: {
    flex: "1 0 21%",
    display: 'flex', 
    justifyContent: 'left',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
      flex: '1 0 30%'
    },
    [theme.breakpoints.down('sm')]: {
      flex: 'none',
      marginTop: '0'
    }
  },
  button: {
    background: "#765B26",
    borderRadius: "26px",
    padding: "11px 54px",
    boxShadow: "0 10px 10px rgba(0,0,0,0.2)",
    color: "white",
    [theme.breakpoints.down('sm')]: {
      padding: '11px 20px'
    }
  },
}));

function App() {
  const classes = useStyles();
  const meta={
    title: 'The Sengol',
    description: '',
    canonical: 'Implement canonical tags to address duplicate content issues and indicate the preferred version of a page to search engines',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'Ceremony, Symbolic action,Handing over power,Mountbatten,Pt Jawaharlal Nehru, Shri C Rajagopalachari,History,Civilization,Chola kingsNew Parliament, Parliament, Sengol,Transfer of PowerSengol, Nehru,India ,BJP,Congress,Lok Sabha,Rajya Sabha,Government, Opposition, Speaker of the House  Independence,Prime Minister of India,President of IndiaThe Sengol, Pandit jawaharlal Nehru,Sengol New Parliament, New Parliament,Ceremony, Symbolic action,Handing over power,Mountbatten,Pt Jawaharlal Nehru, Shri C Rajagopalachari,History, Civilization,Chola kings'
        }
    }
};
  return (
    <DocumentMeta {...meta}>
    <div className={clsx("App", classes.root)}>
      <Header />
      <AppRoutes />
    </div>
    </DocumentMeta>
  );
}

export default App;
