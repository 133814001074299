import React from "react";
import { Routes, Route } from "react-router-dom";
// import Loader from "./components/Loader";

const Home = React.lazy(() => import("./views/Home"));
const SengolContent = React.lazy(() => import("./views/SengolContent"));
const SengolContentParticulars = React.lazy(() =>
  import("./views/ContentParticulars")
);
const MainHomepage = React.lazy(() => import("./views/MainHomepage"));
const HistoricitySengol = React.lazy(() => import("./views/HistoricitySengol"));
const Faq = React.lazy(() => import("./views/Faq"));

export const routes = [
  { path: "/", element: Home },
  { path: "/contents", element: SengolContent },
  { path: "/contentsmain/*", element: MainHomepage },
  {
    path: `/historicitysengol/${localStorage.getItem("SengolLanguage")}`,
    element: HistoricitySengol,
  },
  { path: "/contents/*", element: SengolContentParticulars },
  { path: "/historicitysengol/*", element: SengolContentParticulars },
  { path: "/faq", element: Faq },
];

export const AppRoutes = () => {
  return (
    <Routes>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            element={
              <React.Suspense fallback={<>...</>}>
                <route.element />
              </React.Suspense>
            }
          />
        );
      })}
    </Routes>
  );
};
