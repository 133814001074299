import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './index.css'
import { ThemeProvider, createTheme } from "@mui/material/styles";
import StyledEngineProvider from "@mui/styled-engine/StyledEngineProvider";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xsm: 600,
      
      sm: 800,
      md: 950,
      mlg: 1050,
      lg: 1290,
      xlg: 1400,
      xl: 1536,
      xxl: 1650
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </StyledEngineProvider>
);
