import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import SelectLanguage from "./selectLanguage";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  upperGovtLogo2: {
    height: 50,
  },
}));

export default function TemporaryDrawer(props) {
  const { open, setOpen, list: data, gate, dataa } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    >
      <List>
        <ListItem key={1} disablePadding>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "15px",
                  flexDirection: "column",
                  marginBottom: "30px",
                }}
              >
                {location.pathname != "/" && <SelectLanguage />}

                <Box
                  sx={{
                    display: "flex",
                    marginLeft: "15px",
                    marginTop: "30px",
                  }}
                >
                  <img
                    src={require("./assets/azadikamahotsav.png")}
                    className={classes.upperGovtLogo2}
                  />
                  <img
                    src={require("./assets/g20.png")}
                    className={classes.upperGovtLogo2}
                  />
                </Box>
              </Box>
            }
          />
        </ListItem>

        <ListItem key={2} disablePadding>
          <ListItemText
            primary={
              <Box
                sx={{
                  display: "flex",
                  marginLeft: "15px",
                  flexDirection: "column",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(`/faq`);
                    setOpen(false);
                  }}
                  textAlign="center"
                  sx={{ color: "#585858", marginRight: "20px" }}
                  className={classes.faq}
                >
                  {t("FAQ")}
                </Typography>
              </Box>
            }
          />
        </ListItem>

        {location.pathname != "/" &&
          location.pathname !=
            `/contentsmain/${localStorage.getItem("SengolLanguage")}` && (
            <ListItem key={3} disablePadding>
              <ListItemText
                primary={
                  <Box
                    sx={{
                      display: "flex",
                      marginLeft: "15px",
                      flexDirection: "column",
                      marginBottom: "30px",
                    }}
                  >
                    {/* <Typography
                              onClick={() => {
                                navigate(`/faq`);
                                setOpen(false);
                              }}
                              textAlign="center"
                              sx={{ color: "#585858", marginRight: "20px" }}
                              className={classes.faq}
                            >
                              {t("FAQ")}
                            </Typography> */}

                    <Typography
                      textAlign="center"
                      sx={{
                        color: "#585858",
                        marginRight: "20px",
                        display: "inline-flex",
                      }}
                      className={clsx(classes.faq, "homeiconheadertext")}
                      onClick={() => {
                        navigate(
                          `/contentsmain/${localStorage.getItem(
                            "SengolLanguage"
                          )}`
                        );
                        setOpen(false);
                      }}
                    >
                      <HomeOutlinedIcon
                        className={clsx(classes.whiteIcons, "homeiconheader")}
                      />
                      <span sx={{ marginLeft: "5px", paddingTop: "2px" }}>
                        Main Home
                      </span>
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          )}
      </List>
    </Box>
  );

  const anchor = "right";

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor={anchor} open={open} onClose={() => setOpen(false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
